$('#navbarNav').on('shown.bs.collapse', function (e) {
    $('.navbar-toggler').find('svg').detach();
    $('.navbar-toggler').append('<i class="fa fa-times"></i>');
    $('body').addClass('position-fixed');
});

$('#navbarNav').on('hidden.bs.collapse', function (e) {
    $('body').removeClass('position-fixed');
    $('.navbar-toggler').find('svg').detach();
    $('.navbar-toggler').append('<i class="fa fa-bars"></i>');
});
